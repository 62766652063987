<template>
  <LinearGraphic :options="chartOptions" :data="chartData" :id="id" />
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import moment from "moment";

export default {
  name: "LineChartPeriods",
  components: {
    LinearGraphic: () => import("@/components/ui/graphs/LinearGraphic"),
  },
  computed: {
    ...mapState("stats", ["filters"]),
    last7Days() {
      //SI ELS DOS SON IGUALS
      let dates = [];
      let from = new Date(this.filters.from);
      console.log(from);
      let to = new Date(this.filters.to);
      console.log(to);

      if (from.getTime() == to.getTime()) {
        for (let i = 0; i <= 6; i++) {
          console.log("EQUALS");
          dates[i] = moment().subtract(i, "days").format("YYYY-MM-DD");
        }
        //SI SON DIFERENTS RECOREER RANGO
      } else {
        while (to.getTime() >= from.getTime()) {
          from.setDate(from.getDate() + 1);
          console.log(
            from.getFullYear() +
              "-" +
              (from.getMonth() + 1) +
              "-" +
              from.getDate()
          );
          dates.push(
            from.getFullYear() +
              "-" +
              (from.getMonth() + 1) +
              "-" +
              from.getDate()
          );
        }
      }
      return dates;
    },
    chartData() {
      let datasets = [];
      this.products.forEach((p) => {
        let data = [];
        this.dates.forEach((d) => {
          //COMPARAR SI FECHA ESTA LLENA DEL PRODUCTO QUE TOCA
          let s = this.salesByPeriod.find((s) => {
            // console.log("sbp", s.data, d, s.product_id, p.id);
            return s.data == d && p.id == s.product_id;
          });
          if (s) {
            data.push(s.numVentas);
          } else data.push(0);
          //console.log(data);
        });
        let color = this.randomColor()();
        datasets.push({
          label: p.description,
          backgroundColor: [color],
          borderColor: [color],
          data,
        });
      });

      return {
        //labels: this.products.map((p) => p.description),
        labels: this.last7Days,
        datasets,
      };
    },
    salesByPeriod() {
      return this.$store.state.stats.lists.salesByPeriod;
    },
  },
  mounted() {
    this.fethProducts();
  },
  methods: {
    ...mapGetters("app", ["randomColor"]),
    ...mapActions("inventory", ["getInventorys"]),

    fethProducts() {
      this.getInventorys({
        paginate: { itemsPerPage: -1 },
        filters: {},
      }).then((products) => {
        this.products = products.data;
        console.log(this.products);
      });
    },
  },
  data() {
    return {
      id: "periodGraph",
      products: [],
      dates: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        borderColor: "#fff",
        tension: 0.3,
        plugins: {},
      },
    };
  },
};
</script>
